import React, { useState, useEffect } from 'react'

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';
import { usePrivileges } from '../../../hooks/usePrivileges';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';
import gridLogo from "../../../Images/grid.svg";
import ILRInspectionLotRequestForm from './ILRInspectionLotRequestForm';

// Styling
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

function ILRInspectionLotRequestGrid(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;
    const [tableData, setTableData] = useState([]);
    const [pageSize, setPageSize] = React.useState(5);
    const [open, setOpen] = React.useState(false);
    const [SelectedValue, setSelectedValue] = useState('');


    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;
    const { admin } = usePrivileges();

    const { setAlert } = useAlert();

    const GroupDetails = (params) => {
        return (
            <strong>
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    openDetailsComponent(params.row);
                }}>Details</button>
            </strong>
        )
    }
    const ReProcessRow = (params) => {
        return (
            <strong>
                <button id='activate' type="button" className="btn btn-outline-primary btn-sm" disabled={params.row.status == 'F' || params.row.status == 'X' || params.row.status == 'H' ? false : true} onClick={() => {
                    ReProcessILR(params.row);
                }}>ReProcess</button>
            </strong>
        )
    }
    const ForceReProcessRow = (params) => {
        return (
            <strong>
                <button id='activate' type="button" className="btn btn-outline-primary btn-sm" disabled={params.row.status == 'F' || params.row.status == 'X' || params.row.status == 'H' ? false : true} onClick={() => {
                    ForceReProcessILR(params.row);
                }}>ForceReProcess</button>
            </strong>
        )
    }

    const CancelLotRow = (params) => {
        return (
            <strong>
                <button id='activate' type="button" className="btn btn-outline-primary btn-sm" disabled={params.row.status == 'C' || params.row.status == 'X'  ? true : false} onClick={() => {
                    CancelILR(params.row);
                }}>Cancel</button>
            </strong>
        )
    }
    const ValidateIlrRow = (params) => {
        return (
            <strong>
                <button id='validate' type="button" className="btn btn-outline-primary btn-sm" onClick={() => {
                    ValidateIlr(params.row);
                }}>Validate</button>
            </strong>
        )
    }
    const ProcessRow = (params) => {
        return (
            <strong>
                <button id='activate' type="button" className="btn btn-outline-primary btn-sm" disabled={params.row.status == 'R' ? false : true} onClick={() => {
                    ProcessILR(params.row);
                }}>Process</button>
            </strong>
        )
    }

    var _column = [
        {
            field: 'inspectionLot',
            headerName: 'inspection Lot',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'creationDate',
            headerName: 'Creation Date',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'qmSubSystem',
            headerName: 'QmSubSystem',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'materialId',
            headerName: 'Material Id',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'materialIdDescription',
            headerName: 'Material Id Description',
            width: 150,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'materialGroup',
            headerName: 'Material Group',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        }
        ,
        {
            field: 'plantId',
            headerName: 'Plant Id',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'batchId',
            headerName: 'Batch Id',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'inspectionLotQuantity',
            headerName: 'Lot Qty',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'inspectionLotUnit',
            headerName: 'Lot Unit',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'packageConfiguration',
            headerName: 'Package Configuration',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'siteName',
            headerName: 'Site Name',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'statusMessage',
            headerName: 'Status Message',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'id_numeric',
            headerName: 'id_numeric',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'replied',
            headerName: 'Replied',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'modifiedBy',
            headerName: 'Modified By',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'modifiedDate',
            headerName: 'Modified Date',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    // TODO: What roles should this column be visible for?
    // props.PostData.roleId != 3
    if (admin) {
        _column.push(
            {
                field: '-',
                headerName: 'Details',
                width: 100,
                renderCell: GroupDetails,
                disableClickEventBubbling: true,
                headerAlign: 'center',
                align: 'center'
            });
    }

    if (admin) {
        _column.push({
            field: '/',
            headerName: 'ReProcess',
            width: 100,
            renderCell: ReProcessRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });

        _column.push({
            field: '*',
            headerName: 'Validate',
            width: 100,
            renderCell: ValidateIlrRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });

        _column.push({
            field: '%',
            headerName: 'Process',
            width: 100,
            renderCell: ProcessRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });

        _column.push({
            field: '+',
            headerName: 'ForceReProcess',
            width: 150,
            renderCell: ForceReProcessRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });

        _column.push({
            field: '^',
            headerName: 'Cancel',
            width: 100,
            renderCell: CancelLotRow,
            disableClickEventBubbling: true,
            headerAlign: 'left',
            align: 'left'
        });
    }

    var columns: GridColDef[] = _column;

    const openEditComponent = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openDetailsComponent = (params) => {
        setOpen(true);
        setSelectedValue(JSON.stringify(params).toString());
    };

    const openAddComponent = (params) => {
        setOpen(true);
        setSelectedValue("");
    };

    const handleFormClose = (response) => {
        if (response != null && response == true) {
            LoadILRInspectionLotList();
        }
        setOpen(false);
    };

    const ReProcessILR = async (rowData) => {
        const ReProcessILRFields = {
            inspectionLot: rowData.inspectionLot,
            modifiedBy: username,
        };

        await fetchDataAuthenticated(_apiUrl + 'ReProcessILR', "POST", JSON.stringify(ReProcessILRFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRInspectionLotList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };
    const ForceReProcessILR = async (rowData) => {
        const ReProcessILRFields = {
            inspectionLot: rowData.inspectionLot,
            validationIgnore: true,
            isForceReprocess: true,
            modifiedBy: username,
        };

        await fetchDataAuthenticated(_apiUrl + 'ForceReProcessILR', "POST", JSON.stringify(ReProcessILRFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRInspectionLotList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    const CancelILR = async (rowData) => {
        const CancelILRFields = {
            inspectionLot: rowData.inspectionLot,
            status: "X",
            statusMessage : "Cancelled by the user",
            modifiedBy: username,
        };

        await fetchDataAuthenticated(_apiUrl + 'UpdateIlrStatus', "POST", JSON.stringify(CancelILRFields))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRInspectionLotList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    const ValidateIlr = async (rowData) => {

        await fetchDataAuthenticated(_apiUrl + 'ValidateILR/' + rowData.inspectionLot + "?updateRequest=TRUE", "POST")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRInspectionLotList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    }

    const ProcessILR = async (rowData) => {

        await fetchDataAuthenticated(_apiUrl + 'ProcessILR/' + rowData.inspectionLot, "POST")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                setAlert("success", "Success", response.message);
                LoadILRInspectionLotList();
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    const LoadILRInspectionLotList = async () => {

        await fetchDataAuthenticated(_apiUrl + "GetInspectionRequest")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setTableData(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    useEffect(() => {
        LoadILRInspectionLotList();
    }, []);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-9 titleBar'>
                    <img src={gridLogo} className="gridLogo" />
                    ILR Inspection Lot Request
                </div>
            </div>

            <Box>
                <DataGrid
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        ...tableData.initialState,
                        pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowId={(row: any) => row.inspectionLot}
                />
            </Box>

            <Dialog open={open} onClose={handleFormClose} fullWidth maxWidth="md">
                <DialogTitle className="csDialogTitle">ILR Inspection Lot</DialogTitle>
                <ILRInspectionLotRequestForm SelectedValueTochild={SelectedValue} PostData={props.PostData} CloseDialog={handleFormClose} />
            </Dialog>
        </div>
    );
}
export default ILRInspectionLotRequestGrid;