import React, { useState } from "react";
import { useMsal } from '@azure/msal-react';

export const Profile = (props) => {
    const [dropdown, setDropdown] = useState(false);
    const { instance } = useMsal();
    const handleSignOut = () => {
        instance.logoutPopup({
            postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI || '/',
        });
      };
    return (
        <div className={dropdown ? "subMenu1 clicked" : "subMenu1"} onClick={() => setDropdown(!dropdown)}>
            <div className="submenu-item ipad1"><span>Username : {props && props.username ? props.username : ''}</span></div>
            <div className="submenu-item ipad1"><span>Privileges : {props.privileges && props.privileges.toString()}</span></div>
            <div className="submenu-item ipad1"><button type="button" className="btn btn-outline-secondary" onClick={handleSignOut}>Logout</button></div>
        </div>
    );
};