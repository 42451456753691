import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { ModalDialog } from 'react-bootstrap';

export function DraggableModalDialog(props) {
    const nodeRef = useRef(null);
    let handle = props.handle !== undefined ? props.handle : ".modal-header";
    return (
        <Draggable nodeRef={nodeRef} handle={handle}>
            <ModalDialog ref={nodeRef} {...props} />
        </Draggable>
    );
}