import React from 'react';
import PropTypes from 'prop-types';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal } from 'react-bootstrap';
function SupportDlg(props) {

    SupportDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    }

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Cert App Support
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-start">
                    <p>The Cert App software suite was developed by Jemmac Software Ltd under contract to ExxonMobil.</p>
                </div>

                <p>
                    <strong>Primary support is provided by:</strong>
                </p>
                <p>ExxonMobil Research and Engineering, Control System Support Team (CSST), ExxonMobil Houston Campus</p>
                <p>Email: CSSTHelp (Outlook), or cssthelp@exxonmobil.com (Internet).</p>

                <p>
                    <strong>Secondary support is provided by:</strong>
                </p>
                <p>Jemmac Software, Ltd. (UK)</p>
                {/* TODO: <p>certapp.support@jemmac.com (Internet)</p>*/}
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SupportDlg;