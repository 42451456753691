import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';

// Styling 
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function SiteForm(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;

    const defaultRow = {
        siteId: 0,
        sapSiteCode: "",
        siteName: "",
        description: "",
        thirdPartyLabId: "",
        thirdPartyLabComment: "",
        periodicAnalysis: "",
        statusCoqOkValue: "",
        sampleType: "",
        modifiedBy: "",
        smapiConnection: ""
    };

    useEffect(() => {
    }, []);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const { setAlert } = useAlert();

    const AddSiteflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [SiteValue, SelectedSiteValue] = React.useState(SelectedValue);

    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };

    const handleTextFieldChange = (event) => {
        SiteValue[event.target.id] = event.target.value;
        SelectedSiteValue(SiteValue);
    };

    const saveSiteValues = async () => {

        const serviceUrl = _apiUrl + (AddSiteflag ? "AddSiteDetails" : "UpdateSiteDetails")

        if (AddSiteflag) {
            if (SiteValue.sapSiteCode == null || SiteValue.sapSiteCode === undefined || SiteValue.sapSiteCode === "") {
                setAlert("primary", "Validation", "Please enter SAP Site Code");
                return false;
            }
            if (SiteValue.siteName == null || SiteValue.siteName === undefined || SiteValue.siteName === "") {
                setAlert("primary", "Validation", "Please enter Site Name");
                return false;
            }
            if (SiteValue.thirdPartyLabId == null || SiteValue.thirdPartyLabId === undefined || SiteValue.thirdPartyLabId === "") {
                setAlert("primary", "Validation", "Please enter Third Party Lab Id");
                return false;
            }
            if (SiteValue.thirdPartyLabComment == null || SiteValue.thirdPartyLabComment === undefined || SiteValue.thirdPartyLabComment === "") {
                setAlert("primary", "Validation", "Please enter Third Party Lab Comment");
                return false;
            }
            if (SiteValue.periodicAnalysis == null || SiteValue.periodicAnalysis === undefined || SiteValue.periodicAnalysis === "") {
                setAlert("primary", "Validation", "Please enter Periodic Analysis");
                return false;
            }
            if (SiteValue.sampleType == null || SiteValue.sampleType === undefined || SiteValue.sampleType === "") {
                setAlert("primary", "Validation", "Please enter Sample Type");
                return false;
            }
            if (SiteValue.smapiConnection == null || SiteValue.smapiConnection === undefined || SiteValue.smapiConnection === "") {
                setAlert("primary", "Validation", "Please enter SM API Connection");
                return false;
            }
            if (SiteValue.statusCoqOkValue == null || SiteValue.statusCoqOkValue === undefined || SiteValue.statusCoqOkValue === "") {
                setAlert("primary", "Validation", "Please enter Status COQ Ok Value");
                return false;
            }
        }

        SiteValue.modifiedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(SiteValue))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
            })
            .catch(error => {
                console.error(error);
                setAlert("danger", "Error", error.message);
            });

    }

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="sapSiteCode"
                                label="SAP Site Code"
                                defaultValue={SiteValue.sapSiteCode}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="siteName"
                                label="Site Name"
                                defaultValue={SiteValue.siteName}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="description"
                                label="Description"
                                defaultValue={SiteValue.description}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Box>
                    </div>

                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="thirdPartyLabId"
                                label="Third Party Lab Id"
                                defaultValue={SiteValue.thirdPartyLabId}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="thirdPartyLabComment"
                                label="Third Party Lab Comment"
                                defaultValue={SiteValue.thirdPartyLabComment}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="periodicAnalysis"
                                label="Periodic Analysis"
                                defaultValue={SiteValue.periodicAnalysis}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="sampleType"
                                label="Sample Type"
                                defaultValue={SiteValue.sampleType}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="statusCoqOkValue"
                                label="Status COQ Value"
                                defaultValue={SiteValue.statusCoqOkValue}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="smapiConnection"
                                label="SM API Connection"
                                defaultValue={SiteValue.smapiConnection}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-9'></div>
                <div className='col-md-3 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-primary" onClick={saveSiteValues}>Save</button>
                    </div>
                </div>
            </div>
        </DialogContent>

    );



}
export default SiteForm;