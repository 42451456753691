import React from 'react';
import Alert from 'react-bootstrap/Alert';
import useAlert from './useAlert';
import './NotificationAlert.css';

function NotificationAlert() {

    const { showAlert, variant, header, text, exceptionMessage, closeAlert } = useAlert();

    return (
        <Alert show={showAlert} key={variant} variant={variant} onClose={closeAlert} dismissible className="notification-alert mw-100">
            <Alert.Heading>{header}</Alert.Heading>
            <p>{text}</p>
            {exceptionMessage &&
                <p>{exceptionMessage}</p>
            }
        </Alert>
    );
}

export default NotificationAlert;