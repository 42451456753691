import React from 'react';
import { Link } from 'react-router-dom';

function GEMSLanding(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
            <div className='col-md-10 titleBar'>
				</div>
				<div className='col-md-2'>
					<Link to="/DocLand">
						<button className="btn btn-outline-primary btn-primary-label">Back</button>
					</Link>
				</div>
			</div>
            <div align="center">
                <ul>
                    <li><Link to='/ILRReprocess' className='submenu-item'>View or Reprocess Inspection Lot</Link></li>
                    <li><Link to='/ILRSiteDoc' className='submenu-item'>ILR Site</Link></li>
                    <li><Link to='/ILRMaterialMapDoc' className='submenu-item'>Material Mapping</Link></li>
                    <li><Link to='/ILRComponentMappingDoc' className='submenu-item'>Component Mapping</Link></li>
                    <li><Link to='/SampleFind' className='submenu-item'>Sample Find</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default GEMSLanding;
