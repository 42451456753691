import React from 'react';

import '../../DataGrid.css';

// Styling
import DialogContent from '@mui/material/DialogContent';

function GomResponse(props) {

    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : "";

    const [GomResDetail, SelectedGomResDetail] = React.useState(SelectedValue);

    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>

                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <textarea
                        id="gomResponse"
                        label="Gom Response"
                        type="text"
                        defaultValue={GomResDetail.responseToGom}
                        rows={10}
                    />
                </div>
            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-10'></div>
                <div className='col-md-2 row'>
                    <div className='col-md-12'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Close</button>
                    </div>
                </div>
            </div>

        </DialogContent>

    );
}

export default GomResponse;