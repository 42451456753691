import React from 'react'
import { Link } from 'react-router-dom';
function GOMSampleFind(props) {
	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-10 titleBar'>
					<h4>Finding the Sample for the GOM</h4>

				</div>
				<div className='col-md-2'>
					<Link to="/GOMLanding">
						<button className="btn btn-outline-primary btn-primary-label">Back</button>
					</Link>
				</div>
			</div>
			<div>
			<h5>Sample Find</h5>
				<p>
				Samples are found using sample type, blend date, blend, tank and a window of days created from the blend date and configuration to limit the time frame used.
				</p>
				<p>
				If the blend date request is not a valid date or the value it “MOST RECENT” the query is by tank with the most recent blend sample in the tank being returned.  Sample  type limits the samples that can be used.
				</p>
				<ul>
                    <li><b>Parameter (Source): </b>Description.</li>
                    <li><b>Sample Type (SITE table): </b> SAMPLE.Sample_type</li>
                    <li><b>Blend Date (GOM): </b>Date that Blend started. Value is often slightly different between SampleManager and GOM due to manual entry in both systems. Can be “MOST RECENT” or it may be an invalid date from GOM in which case the CertApp will consider it to be “MOST RECENT”.</li>
                    <li><b>Tank (GOM): </b> Physical tank. Mapped to equipmentcode in the SM-API.</li>
                    <li><b>Blend (GOM): </b> Identification given to blend by process control.  Mapped to blendId in the SM-API.</li>
                    <li><b>Window (Component Mapping): </b> Used to build a time frame around the request limiting the number of days searched for the sample.</li>
                    <li><b>Active</b> allows the user to remove the record from the list without deleting.</li>
                </ul>
				<br></br>
				<div>
				<h5>Sample by Nomination</h5>
				<p>
				A nomination process can be used to identify the samples for CofQ.  An interface with SAP will send the nomination data to SampleManager and a function within SampleManager allows the lab to associate a sample with the nomination.  GOM will then request the sample by nomination key, item and sequence id.  The CertApp will find the sample id_numeric by nomination and use the id_numeric to get the sample following all other checks and mappings.  Details about the interface that sends the nomination data to SampleManager and the SampleManager function to associate the nomination with the id_numeric are in the SampleManager documentation.
				</p>
				</div>
			</div>
		</div>
	);
}
export default GOMSampleFind;