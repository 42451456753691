import React from 'react'
import { Link } from 'react-router-dom';

function IlrStatus(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>ILR Status</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/SupportLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>Status</h5>
                <p>A Status column on the ILRInspectionLotRequest allows the user to see what stage the request is at during processing.  A Status Message is also provided which will be populated with some brief
                    information that should explain why the request is in the particular status.  
                </p>
                <p>
                    <ul>
                        <li>N - New request from GEMS.</li>
                        <li>R - Ready for reply.  The request has been validated for mapping and matched with the MLP.  No errors found.</li>
                        <li>H - Hold - There is an issue and the request is being held until that issue is resolved.</li>
                        <li>W - Waiting - The request is waiting for the sample to be Authorized</li>
                        <li>C - Completed - The reply has been sent to GEMS</li>
                        <li>F - Failed  - Interface error between the CertApp and XI or the current interface method.  The application will resend the reply.</li>
                        <li>X - Cancelled - The ILR can be reprocessed by the user.  This would set the status back to N and submit it back to the Service Bus.</li>
                    </ul>
                </p>

            </div>

        </div>
    );
}
export default IlrStatus;