import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';

// Styling
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function ILRMaterialMapForm(props) {

    const _apiUrl = process.env.REACT_APP_API_URL;

    const [siteDetails, setSiteDetails] = useState([]);

    const defaultRow = {
        ilrMaterialMapId: 0,
        site: "",
        smProduct: "",
        sapMaterialGroup: "",
        mlpLevel: "",
        sampleTemplate: "",
        modifiedBy: "",
        modifiedOn: ""

    };

    const { setAlert } = useAlert();

    const GetIlrSiteDetails = async () => {

        await fetchDataAuthenticated(_apiUrl + "IlrUniqueSiteDetails")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setSiteDetails(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    }

    useEffect(() => {
        GetIlrSiteDetails();
    }, []);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const AddILRMaterialMapFlag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [MaterialMapValue, SelectedMaterialMapValue] = React.useState(SelectedValue);

    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };

    const [site, setSite] = React.useState(SelectedValue.site);
    const handleChangeSite = (event) => {
        setSite(event.target.value);
        MaterialMapValue.site = event.target.value;
        SelectedMaterialMapValue(MaterialMapValue);
    };

    const handleTextFieldChange = (event) => {
        MaterialMapValue[event.target.id] = event.target.value;
        SelectedMaterialMapValue(MaterialMapValue);
    };

    const saveILRMaterialMapValues = async () => {

        const serviceUrl = _apiUrl + (AddILRMaterialMapFlag ? "AddIlrMaterialMap" : "UpdateILRMaterialMap")

        if (AddILRMaterialMapFlag) {
            if (MaterialMapValue.site == null || MaterialMapValue.site === undefined || MaterialMapValue.site === "") {
                setAlert("primary", "Validation", "Please select Site");
                return false;
            }
            if (MaterialMapValue.smProduct == null || MaterialMapValue.smProduct === undefined || MaterialMapValue.smProduct === "") {
                setAlert("primary", "Validation", "Please enter SMProduct");
                return false;
            }
            if (MaterialMapValue.sapMaterialGroup == null || MaterialMapValue.sapMaterialGroup === undefined || MaterialMapValue.sapMaterialGroup === "") {
                setAlert("primary", "Validation", "Please enter SAP Material Group");
                return false;
            }
            if (MaterialMapValue.mlpLevel == null || MaterialMapValue.mlpLevel === undefined || MaterialMapValue.mlpLevel === "") {
                setAlert("primary", "Validation", "Please enter MLP Level");
                return false;
            }
            if (MaterialMapValue.sampleTemplate == null || MaterialMapValue.sampleTemplate === undefined || MaterialMapValue.sampleTemplate === "") {
                setAlert("primary", "Validation", "Please enter Sample Template");
                return false;
            }

        }

        MaterialMapValue.modifiedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(MaterialMapValue))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                            <InputLabel id="lblsiteId" className='required'>Site</InputLabel>
                                <Select
                                    labelId="lblSiteId"
                                    id="isiteId"
                                    value={site}
                                    label="Site *"
                                    onChange={handleChangeSite}
                                >
                                    <MenuItem value="">Select Site</MenuItem>
                                    {siteDetails.map((sitedt, index) => {
                                        return (
                                            <MenuItem value={sitedt.siteName} key={"lblsiteId" + sitedt.siteId + index}>{sitedt.siteName}</MenuItem>
                                        );
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="smProduct"
                                label="SMProduct"
                                defaultValue={MaterialMapValue.smProduct}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 20 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="sapMaterialGroup"
                                label="SAPMaterialGroup"
                                defaultValue={MaterialMapValue.sapMaterialGroup}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="mlpLevel"
                                label="MLP Level"
                                defaultValue={MaterialMapValue.mlpLevel}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="sampleTemplate"
                                label="Sample Template or Workflow"
                                defaultValue={MaterialMapValue.sampleTemplate}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-9'></div>
                <div className='col-md-3 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-primary" onClick={saveILRMaterialMapValues}>Save</button>
                    </div>
                </div>
            </div>
        </DialogContent>

    );
}

export default ILRMaterialMapForm;