import React from 'react'
import { Link } from 'react-router-dom';
function CofQConfiguration(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>GOM CofQ Configuration</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>

                <h4>To test or setup a product the following steps as needed.</h4>
                <p />
                This is intended to be an outline of the steps.  Details of how to setup each step are in the Documentation.
                <p />
                <h5>GOM will need to provide the following to build the GOM Site Mapping:</h5>
                <ul>
                    <li>GOM Site Code.</li>
                </ul>
                <p />
                <h5>Create a Component Mapping record:</h5>
                <ul>
                    <li>GOM / Component Mapping</li>
                </ul>
                <p />
                <h5>Create a Component Group Lookup Record</h5>
                <ul>
                    <li>This maps the request from GOM to the Component Group</li>
                </ul>
                <p />
                <h5>Optionally Create Translation Records</h5>
                <ul>
                    <li>Only Chinese is initially supported but any language potentially could be added.  The interface is setup to handle ISO Language Codes.</li>
                    <li>The ISO code for Chinese is zn</li>
                </ul>
                <p />
                When the request is sent from GOM, the CertApp will reply with the sample results or an error message indicating why the results are not avaliable
                Additionally information about the processing of the GOM Request can be viewed on the Logs tab.  Set the Site, Dates, Log Type = GOM and click filter to see logs of
                the processing steps.
                <p />
                Log details are in JSON format and can be copied and saved to a.JSON file for viewing in Chrome or any text editor capable of formatting JSON text.


            </div>

        </div>
    );
}
export default CofQConfiguration;

