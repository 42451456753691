import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';

import { BrowserRouter } from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { client as publicClientApplication } from './utilities/AuthUtils';

import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

publicClientApplication.initialize();

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
          <BrowserRouter basename={baseUrl}>
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                  <App />
              </MsalAuthenticationTemplate>
              <UnauthenticatedTemplate>
                  <p>Unauthorized</p>
              </UnauthenticatedTemplate>
          </BrowserRouter>
      </ MsalProvider>
  </React.StrictMode>,
  rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
