import { PublicClientApplication } from '@azure/msal-browser';

const syncFetch = require('sync-fetch');

const _apiUrl = process.env.REACT_APP_API_URL;

const clientConfig = syncFetch(_apiUrl + 'api/config/clientConfig').json();
//const clientConfig = syncFetch('https://wa-certapp-api-jemmac-dev01.azurewebsites.net/api/config/clientConfig').json();
// const clientConfig = syncFetch('https://api.dev.certapp.qm.xom.cloud/api/config/clientConfig').json();

const config = {
    auth: {
        clientId: clientConfig.clientId,
        authority: clientConfig.authority,
        redirectUri: clientConfig.redirectUri
    }
};

export const client = new PublicClientApplication(config);

export const account = client.getAllAccounts()[0];

export const getAccessToken = async () => {

    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#configuration
    let tokenRequest = {
        scopes: [clientConfig.tokenScope],
        account: client.getAllAccounts()[0]
    };

    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html#acquiretokensilent
    let tokenResponse = await client.acquireTokenSilent(tokenRequest);

    return tokenResponse.accessToken;
}

export const signOut = () => {

    var account = client.getAllAccounts()[0];

    const logoutRequest = {
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        logoutHint: account.idTokenClaims.login_hint
    }

    client.logoutRedirect(logoutRequest);
};

//import { PublicClientApplication } from '@azure/msal-browser';

//const syncFetch = require('sync-fetch');
//// const clientConfig = syncFetch('api/config/clientConfig').json();

//const config = {
//    auth: {
//        // https://learn.microsoft.com/en-us/entra/identity-platform/scenario-spa-acquire-token?tabs=react
//        // https://learn.microsoft.com/en-us/answers/questions/545439/aadsts65001-the-user-or-administrator-has-not-cons

//        // BE Client ID
//        // clientId: '4539a692-a90f-4445-bd93-869bf20ad461',

//        // FE Client ID
//        // clientId: 'e69dfc2c-3643-44f5-8c17-267ac9585798',

//        // JEMMAC QIRMS FE Client ID
//        //clientId: 'b5964696-a980-4059-aac5-cae21b15023d', // Bearer error="invalid_token", error_description="The audience 'api://e18b92ac-42ef-4b2e-a4ae-49829d41a532' is invalid"

//        // Jemmac CertApp FE Client ID
//        clientId: '98bfbe93-c40c-45af-8ce4-663cee1ab57e',
//        // EXXON
//        // authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906',

//        // JEMMAC
//        authority: 'https://login.microsoftonline.com/63ed6050-9cd4-45a9-bde6-df17a88d9341',
        
//        // REMOTE:
//        // redirectUri: 'https://web.dev.certapp.qm.xom.cloud'
//        // LOCAL: 
//        redirectUri: 'https://localhost:7206/'
        
//        // clientId: clientConfig.clientId,
//        // authority: clientConfig.authority,
//        // redirectUri: clientConfig.redirectUri


//        // Grant consent
//        // https://login.microsoftonline.com/63ed6050-9cd4-45a9-bde6-df17a88d9341/adminconsent?client_id=98bfbe93-c40c-45af-8ce4-663cee1ab57e
//    }
//};

//export const client = new PublicClientApplication(config);

//export const account = client.getAllAccounts()[0];

//export const getAccessToken = async () => {

//    console.log('clientId ' + config.auth.clientId);
//    console.log('authority ' + config.auth.authority);
//    console.log('redirectUri ' + config.auth.redirectUri);

//    console.log("In getAccessToken");

//    //console.log("In getAccessToken - account.username=" + account.username);

//    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#configuration
//    let tokenRequest = {
//        // scopes: [clientConfig.tokenScope],
//        // scopes: ['api://4539a692-a90f-4445-bd93-869bf20ad461/.default'],

//        // CERT APP DEV SCOPE
//        // scopes: ['https://web.dev.certapp.qm.xom.cloud/.default'],

//        // JEMMAC QIRMS BE is the scope
//        //scopes: ['api://e18b92ac-42ef-4b2e-a4ae-49829d41a532/.default"'],

//        // JEMMAC CERT APP BE SCOPE
//        scopes: ['api://225aa175-6596-4e72-8597-707eca61d781/.default"'],

//        account: client.getAllAccounts()[0]
//    };

//    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/classes/_azure_msal_browser.publicclientapplication.html#acquiretokensilent
//    let tokenResponse = await client.acquireTokenSilent(tokenRequest);

//    console.log("In getAccessToken - tokenResponse=");
//    console.log(tokenResponse);

//    let t = tokenResponse.accessToken;

//    console.log("In getAccessToken returning t='" + t + "'");

//    return t;

//}

//export const signOut = () => {

//    var account = client.getAllAccounts()[0];

//    const logoutRequest = {
//        postLogoutRedirectUri: config.postLogoutRedirectUri,
//        logoutHint: account.idTokenClaims.login_hint
//    }

//    client.logoutRedirect(logoutRequest);
//};