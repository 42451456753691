import React from 'react'
import { Link } from 'react-router-dom';

function SampleFind(props) {
	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-md-10 titleBar'>
					<h4>Finding the Sample for the GEMS ILR</h4>
				</div>
				<div className='col-md-2'>
					<Link to="/GEMSLanding">
						<button className="btn btn-outline-primary btn-primary-label">Back</button>
					</Link>
				</div>
			</div>
			<div>
				<h5>SampleManager 11 - GRC set the Following on the sample template:</h5>
				<ul>
					<li>SAMPLE.PRODUCT = Product in SampleManager mapped to SAP Material Group</li>
					<li>SAMPLE.SEND_TO_SAP = 'T' - True</li>
					<li>SAMPLE.SENT_TO_SAP = 'F' - False</li>
					<li>SAMPLE.SAP_BATCH = The batch number as expected from GEMS.</li>
					<ul>
						<li>This needs to match the "batchId" in the ILR Request from SAP/GEMS.</li>
						<li>This can be a lot number in the case of Polymers or an evergreen batch number in the case of Chemicals where the batch number does not change.</li>
					</ul>
				</ul>
				<p />
				<h5>SampleManager 12 and later (SQL Server) set the Following on the sample workflow:</h5>
				<ul>
					<li>SAMPLE.PRODUCT = Product in SampleManager mapped to SAP Material Group</li>
					<li>SAMPLE.SEND_TO_SAP = 'T' - True</li>
					<li>SAMPLE.SENT_TO_SAP = 'F' - False - This will be ignored in some cases.</li>
					<li>SAMPLE.XOM_BATCH_ID = The batch number as expected from GEMS.</li>
					<ul>
						<li>This needs to match the "batchId" in the ILR Request from SAP/GEMS.</li>
						<li>This can be a lot number in the case of Polymers or an evergreen batch number in the case of Chemicals where the batch number does not change.</li>
					</ul>
				</ul>
				<p />

				<h5>Setting the "Sample Find Routine":</h5>
				<ul>
					<li>The "Sample Find Routine" is set on the ILR Site record under the GEM Mapping</li>
					<li>The routine is set by SAP Site Code and Plant Code so that each business unit within a site can have a unique method of finding the sample.</li>
					<li>The Sent_To_Sap flag on the sample is used to determine if a single sample can be used for more than one ILR request from GEMS.</li>
					<li>Consider the windows of days the request should be limited to if Batch or Lot Numbers are reused or evergreen.</li>
				</ul>
				<p />

				<h5>The following routines are used to find the sample:</h5>
				<ul>
					<ul>
						<h4>SampleByBlendIdProductSentToSapFalse:</h4>
						<b>SampleManager 12 and later on SQL Server searches by:</b>
						<ul>
							<li>Matching the batchId from SAP to the XOM_BLEND_ID in the SampleManager.</li>
							<li>Mapping the Material Group from SAP to the SampleManager Product (MLP_Header.Identity) and Matching that to the SAMPLE.PRODUCT.</li>
							<li>SEND_TO_SAP - "T" - True</li>
							<li>SENT_TO_SAP - "F" - False </li>
							<li>Sampled_Date on the sample must be within 100 days for the creationDate on the SAP Request.</li>
							<li>Searches both Active and Committed table sets in SampleManager</li>
						</ul>
						<b>SampleManager GRC on Oracle - Generally not used as the SAMPLE.SAP_BATCH was the original design. Searches by:</b>
						<ul>
							<li>Matching the batchId from SAP to the LOCAL_BLEND_ID in the SampleManager.</li>
							<li>Mapping the Material Group from SAP to the SampleManager Product (MLP_Header.Identity) and Matching that to the SAMPLE.PRODUCT.</li>
							<li>SEND_TO_SAP must be "T" - True.</li>
							<li>SENT_TO_SAP must be "F" - False.  On sending the reply this will be updated to "T".  This prevents resending the same sample for more than one request.</li>
							<li>Sampled_Date on the sample must be within 100 days for the creationDate on the SAP Request.</li>
							<li>Searches both Active and Committed table sets in SampleManager</li>
						</ul>
					</ul>
					<ul>

						<h4>SampleByBlendIdProductIgnoreSentToSap:</h4>
						<b>SampleManager 12 and later on SQL Server searches by:</b>
						<ul>
							<li>This is the same as SampleByBlendIdProductSentToSapFalse but ignores the SENT_TO_SAP column.</li>
							<li>This allows the same sample to be used for more than one request. Specifically designed for cases like NDG where the lot is originally written on as VR BULK and
								then later packaged out and requests are resent for the packaged portion of the lot.</li>
						</ul>
					</ul>

					<ul>

						<h4>SampleBySapBatchProductSentToSapFalse</h4>
						<p />
						<b>SampleManager 12 and later on SQL Server</b>
						<ul>
							<li>Does not apply as the SAP_BATCH does not exist on the SAMPLE table.</li>
						</ul>
						<p />
						<b>SampleManager GRC on Oracle searches by:</b>
						<ul>
							<li>Matching the batchId from SAP to the SAP_BATCH in the SampleManager.</li>
							<li>Mapping the Material Group from SAP to the SampleManager Product (MLP_Header.Identity) and Matching that to the SAMPLE.PRODUCT.</li>
							<li>SEND_TO_SAP must be "T" - True.</li>
							<li>SENT_TO_SAP must be "F" - False.  On sending the reply this will be updated to "T".  This prevents resending the same sample for more than one request.</li>
							<li>Sampled_Date on the sample must be within 100 days for the creationDate on the SAP Request.</li>
							<li>Searches both Active and Committed table sets in SampleManager</li>
						</ul>
						<p />
						<h4>SampleBySapBatchProductIgnoreSentToSap</h4>
						<ul>
							<li>This is the same as SampleBySapBatchProductSentToSapFalse but ignores the SENT_TO_SAP column.</li>
							<li>This allows the same sample to be used for more than one request.  Specifically cases like NDG where the lot is originally written on as VR BULK
								and then later packaged out and requests are resent for the packaged portion of the lot.</li>
							<li>Sampled_Date on the sample must be within 356 days for the creationDate on the SAP Request.</li>
						</ul>
						<p />
						<h4>SampleSkipCancelILR</h4>
						<ul>
							<li>Cancel the ILR Request without processing.</li>
							<li>This allows the request for a specific SAP SubSystem and Site Code to be cancelled.  Useful where site is receiving requests but has not yet configured
          						the interface of chooses to manually handle the data.  Cancelling the ILR stops messaging about failures and eliminates unneeded processing.</li>
							<li>The ILR can be submitted for processing if the Sample Find Routine is specified on the ILR Site table.</li>
						</ul>
						<p />
						<h4>Additional Routines</h4>
						<ul>
							<li>Additional routine would need to be written into the CertApp API.</li>
							<li>Consider the method of finding the samples and the date range to search when specifying new routines.</li>
							<li>Consider that the SM-API searches both the Active and Committed Table Sets in SampleManager.</li>
						</ul>
					</ul>
				</ul>
				<p>
					Updated: February 2024
				</p>
			</div>
		</div>
	);
}
export default SampleFind;