import React from 'react'
import { Link } from 'react-router-dom';

function Resources(props) {
    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>Resources</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/SupportLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                
                <h5>GitHub</h5>
                <p>These are the GitHub Repositories as of 2024 when the application was rolled out.
                <ul>
                    <li><b>API Code</b> qm-certapp-coq-api </li>
                    <li><b>User Interface</b> qm-certapp-coq-ui </li>
                </ul>
                </p>
                <p>User Interface:
                    <ul>
                        <li> <a href="https://web.dev.certapp.qm.xom.cloud/" target="_blank" a>Development - https://web.dev.certapp.qm.xom.cloud</ a> </li>
                        <li> <a href="https://web.acc.certapp.qm.xom.cloud/" target="_blank" a>Acceptance - https://web.acc.certapp.qm.xom.cloud</ a> </li>
                        <li> <a href="https://web.prd.certapp.qm.xom.cloud/" target="_blank" a>Production - https://web.prd.certapp.qm.xom.cloud</ a> </li>
                    </ul>
                </p>
                <p>Swagger Pages for CertApp (You will need a PostMan file or similar to get the Bearer Token):
                    <ul>
                        <li> <a href="https://api.dev.certapp.qm.xom.cloud/swagger/index.html" target="_blank" a>Development - https://api.dev.certapp.qm.xom.cloud/swagger/index.html</ a> </li>
                        <li> <a href="https://api.acc.certapp.qm.xom.cloud/swagger/index.html" target="_blank" a>Acceptance - https://api.acc.certapp.qm.xom.cloud/swagger/index.html</ a> </li>
                        <li> <a href="https://api.prd.certapp.qm.xom.cloud/swagger/index.html" target="_blank" a>Production - https://api.prd.certapp.qm.xom.cloud/swagger/index.html</ a> </li>
                    </ul>
                </p>
                <p>Swagger Pages for SM-API (You will need a PostMan file or similar to get the Bearer Token):
                    <ul>
                        <li> <a href="https://hoewad927.na.xom.com:8043/swagger/index.html" target=" _blank" a>Development - https://hoewad927.na.xom.com:8043/swagger/index.html</ a> </li>
                        <li> <a href="https://hoewaa927.na.xom.com:8043/swagger/index.html" target=" _blank" a>Acceptance - https://hoewaa927.na.xom.com:8043/swagger/index.html</ a></li>
                        <li> <a href="https://hoewap927.na.xom.com:8043/swagger/index.html" target=" _blank" a>Production - https://hoewap927.na.xom.com:8043/swagger/index.html</ a></li>
                    </ul>
                </p>
                <p>Azure Resources:
                    <ul>
                        <li>Development Database - sqls-certapp-ussc-dev01.database.windows.net - sqldb-certapp-ussc-dev01 </li>
                        <li>Acceptance Database - sqls-certapp-ussc-acc01.database.windows.net - sqldb-certapp-ussc-acc01 </li>
                        <li>Acceptance Database - sqls-certapp-ussc-prd01.database.windows.net - sqldb-certapp-ussc-prd01 </li>
                        <li>Azure Resource Group Development - RG-certapp-ussc-dev</li>
                        <li>Azure Resource Group Acceptance - RG-certapp-ussc-acc</li>
                        <li>Azure Resource Group Development - RG-certapp-ussc-prd</li>
                    </ul>
                </p>
                   
            </div>

        </div>
    );
}
export default Resources;