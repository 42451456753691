import React from 'react'
import { Link } from 'react-router-dom';
import ILRComponentMapForm from "../../../../Images/ILRComponentMapForm.jpg";
import ILRComponentMapGrid from "../../../../Images/ILRComponentMapGrid.jpg";
				
function ILRComponentMappingDoc(props) {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-10 titleBar'>
                    <h4>ILR Component Mapping Header</h4>
                </div>
                <div className='col-md-2'>
                    <Link to="/GEMSLanding">
                        <button className="btn btn-outline-primary btn-primary-label">Back</button>
                    </Link>
                </div>
            </div>
            <div>
                <h5>Component Map</h5>
                <ul>
                    <li><b>Site Name</b> SampleManager instance or site to relate records in this Cert App to other data.</li>
                    <li><b>Product</b> SampleManager Product - MLP Identity.</li>
                    <li><b>ILR Component Mapping Button</b> creates a new record.</li>
                    <li><b>Details</b> Opens the editor for the list of components</li>
                    <li><b>Delete</b> Removes the record from the database.  This is a hard delete as in the record is removed from the database.</li>
                </ul>
                <p />
                <img src={ILRComponentMapForm} className="ILRComponentMapForm" alt="ILR Component Map Form" />
                <p />
                <h5>Component Map Grid</h5>
                <ul>
                    <li><b>Analysis</b> SampleManager Analysis.  Optional.</li>
                    <li><b>Component</b> SampleManager Component.  Required.</li>
                    <li><b>SAP Char Id</b> First 4 characters of the SAP Char Id stripped of underscores "_"</li>
                    <li><b>Result Type</b> SampleManager Result type.  N: Numeric or O: Option.</li>
                    <li><b>Unit Of Measure</b> SampleManager Unit Of Measure.  The SAP UOM may match this or be an Alias in SampleManager or match the GRDB code on the Unit Header in SampleManager.</li>
                    <li><b>Decimal Places</b> SampleManager Places expected on the analysis.</li>
                    <li><b>Order</b> Optional</li>
                    <li><b>Edit Actions</b> Edit or Delete are to the far right.</li>
                </ul>
                <p />
                <img src={ILRComponentMapGrid} className="ILRComponentMapGrid" alt="ILR Component Map Grid" />
            </div>

        </div>
    );
}
export default ILRComponentMappingDoc;